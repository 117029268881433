import { HStack, VStack } from '@chakra-ui/react'
import { Input, LevelSelect, SubjectSelect, TagsInput, TextArea } from 'components/design-system/forms'
import ModalEditor from './modal-editor'

const BasicDataEditor = ({
  title,
  children = null,
  data,
  validationSchema,
  withInternalTitle = false,
  withSubject,
  withLevel,
  isLoading,
  isSaving = false,
  isVisible,
  onClose,
  onSubmit,
  forLessonPath = false
}) => {
  return (
    <ModalEditor
      title={title}
      data={data}
      validationSchema={validationSchema}
      isLoading={isLoading}
      isSaving={isSaving}
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Input label="Title" name="title" isRequired={true} placeholder="Title" marginBottom={'15px'} />

      {withInternalTitle && (
        <Input
          label="Internal Title"
          name="internalTitle"
          isRequired={false}
          placeholder="Internal Title"
          marginBottom={'15px'}
        />
      )}

      {!forLessonPath && (
        <TextArea label="Description" name="description" placeholder="Description" marginBottom={'15px'} />
      )}

      {withLevel && withSubject && (
        <HStack justifyContent={'space-between'} marginBottom={'15px'}>
          <VStack>
            <LevelSelect label="Level" name="level.id" placeholder="Choose Level" isRequired={true} />
          </VStack>
          <VStack>
            <SubjectSelect
              label="Subject"
              name="subject.id"
              placeholder="Choose Subject"
              isRequired={true}
              forPath={forLessonPath}
            />
          </VStack>
        </HStack>
      )}

      {withLevel && !withSubject && (
        <LevelSelect label="Level" name="level.id" placeholder="Choose Level" isRequired={true} marginBottom={'15px'} />
      )}

      {!withLevel && withSubject && (
        <SubjectSelect
          label="Subject"
          name="subject.id"
          placeholder="Choose Subject"
          forPath={forLessonPath}
          marginBottom={'15px'}
        />
      )}

      {children}

      <TagsInput label="Tags" name="metaTags" placeholder="Type a tag" marginBottom={'15px'} />
    </ModalEditor>
  )
}

export default BasicDataEditor
