import { Divider, FormControl, FormHelperText, HStack, Stack } from '@chakra-ui/react'
import { Domain } from '@heytutor/core'
import { Select } from '../forms'
import Input from '../forms/input'

export const EditionForm = () => {
  const splitCommaSeparated = (event) => {
    const code = event.keyCode || event.which

    // Letter (a-Z) or number (0-9) key pressed
    if ((code >= 65 && code <= 90) || (code >= 48 && code <= 57)) {
      return (event.target.value = event.target.value.replace(/,\s*/g, ', '))
    }
  }

  return (
    <>
      <Input
        label="CC Standards"
        name="cc_standards"
        helper="CC Standards must contain a maximum of 150 characters."
        isRequired={true}
        placeholder="CC Standards"
      />
      <Input
        label="Description"
        name="description"
        helper="Description should contain at maximum 255 characters."
        isRequired={false}
        placeholder="Description"
      />

      <Select
        label="Domain"
        name="domain"
        isRequired={false}
        placeholder="Select Domain"
        items={Object.keys(Domain).map((domain) => ({ value: domain, name: Domain[domain] }))}
      />

      <Input
        label="Objective"
        name="objective"
        helper="Objective should contain at maximum 255 characters."
        isRequired={false}
        placeholder="Objective"
      />

      <Divider my={4} />

      <FormControl>
        <Stack justifyContent={'space-between'}>
          <HStack>
            <Input
              label="TEKS Standards"
              name="teks_standards"
              isRequired={false}
              placeholder="TEKS Standards"
              onKeyUp={splitCommaSeparated}
            />
            <Input
              label="BEST Standards"
              name="best_standards"
              isRequired={false}
              placeholder="BEST Standards"
              onKeyUp={splitCommaSeparated}
            />
            <Input
              label="NJSLS Standards"
              name="njsls_standards"
              isRequired={false}
              placeholder="NJSLS Standards"
              onKeyUp={splitCommaSeparated}
            />
            <Input
              label="NY-Standard CCSS"
              name="ny_standards"
              isRequired={false}
              placeholder="NY-Standard CCSS"
              onKeyUp={splitCommaSeparated}
            />
            <Input
              label="PA Core Standards"
              name="pa_standards"
              isRequired={false}
              placeholder="PA Core Standards"
              onKeyUp={splitCommaSeparated}
            />
          </HStack>
        </Stack>
        <FormHelperText>Each standard must contain a maximum of 150 characters..</FormHelperText>
      </FormControl>
    </>
  )
}
